import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import {StaticQuery, graphql, Link} from 'gatsby'
import Img from "gatsby-image"

const Container = styled.div`
`

const ServingContainer = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 800;
`
const Serving = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: steelblue;
`
const Cities = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;
  padding: 0 3rem;
`
const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 1em;
`
const RoofImage = styled(Img)`
  width: 100%
`
const Strengths = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 3rem;
` 
const Strength = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 48%;
  margin: 1rem .1rem;
  border-radius: 1em;
`
const StrengthTop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`
const IconContainer = styled.div`
  width: 20%;
  height: auto;
  margin-right: 1rem;
`
const Icon = styled(Img)`
  width: 100%;
`
const StrengthTitle = styled.div`
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  max-width: 70%;
`
const StrengthCopy = styled.div`
  order: 2;
  text-align: justified;
  padding: .1rem;
  margin: .3rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`
const Copy = styled.div`
  align-text: center;
`

const IndexPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          roofer: file(relativePath: { eq: "nicehome.png" }) {
            childImageSharp {
              fluid(quality: 20, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          strength1: file(relativePath: { eq: "check.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          strength2: file(relativePath: { eq: "house.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          strength3: file(relativePath: { eq: "search.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          strength4: file(relativePath: { eq: "sheild.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          strength5: file(relativePath: { eq: "safety.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          strength6: file(relativePath: { eq: "world.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }            
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Home" />

          <Container>
          <ServingContainer>
            <Copy>
              <Serving>Proudly Serving</Serving><br/>
              <Cities> Lady Lake, Wildwood, Fruitland Park, Leesburg Summerfield, Weirsdale, Oxford The Villages</Cities>
            </Copy>
          </ServingContainer>

          <ImageContainer>
            <RoofImage fluid={data.roofer.childImageSharp.fluid}></RoofImage>
          </ImageContainer>

          <Strengths>
            <Strength>
            <StrengthTop>
              <IconContainer><Icon fluid={data.strength1.childImageSharp.fluid} /></IconContainer>
              <StrengthTitle>Experienced Professionals</StrengthTitle>
            </StrengthTop>            
                <StrengthCopy>Our professionals have worked on homes and businesses like yours for years and have the experience to do it right the first time.</StrengthCopy>
            </Strength>
            <Strength>
              <StrengthTop>
              <IconContainer><Icon fluid={data.strength5.childImageSharp.fluid} /></IconContainer>
                <StrengthTitle>Proven Safety Record</StrengthTitle>
              </StrengthTop>
                <StrengthCopy>We follow the latest safety rules and regulations to ensure a safe product and environment.</StrengthCopy>
            </Strength>            
            <Strength>
            <StrengthTop>
              <IconContainer><Icon fluid={data.strength2.childImageSharp.fluid} /></IconContainer>
                <StrengthTitle>Quality Materials & Products</StrengthTitle>
                </StrengthTop>            
                <StrengthCopy>We use only the best materials to ensure the product is made to last for years to come. </StrengthCopy>
            </Strength>
            
            
            <Strength>
              <StrengthTop>
                <IconContainer><Icon fluid={data.strength6.childImageSharp.fluid} /></IconContainer>
                <StrengthTitle>World-Class Customer Service</StrengthTitle>
              </StrengthTop>
              <StrengthCopy>We treat you like family, because that's how it should be.</StrengthCopy>
            </Strength>
            <Strength>
              <StrengthTop>
                <IconContainer><Icon fluid={data.strength4.childImageSharp.fluid} /></IconContainer>
                <StrengthTitle>Lifetime Protection</StrengthTitle>
              </StrengthTop>
              <StrengthCopy>Ask about our lifetime protection offerings so that you never have to worry again!</StrengthCopy>
            </Strength>
            <Strength>
              <StrengthTop>
                <IconContainer><Icon fluid={data.strength3.childImageSharp.fluid} /></IconContainer>
                <StrengthTitle>Search No Further</StrengthTitle>
              </StrengthTop>
               <StrengthCopy>A wide range of services offered for residential, commercial, repairs, maintenance, and more!</StrengthCopy>
            </Strength>
          </Strengths>
          To request a free quote, fill out the form to the right or <Link to='/contactus'>contact us</Link> to have one of our staff members call or email you at your convenience. 
          <br/><br/> 
          </Container>
        </Layout>
      )}
    />
  )
}
      export default IndexPage
